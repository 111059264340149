import clearanceProductAuth from './crm_clearanceProductAuth.json';
import transferProductAuth from './crm_transferProductAuth.json';
import price from './crm_price.json';
import client from './crm_client.json';

export default {
  ...clearanceProductAuth,
  ...transferProductAuth,
  ...price,
  ...client
};
