const clearanceProductConfigRoute = {
  name: 'psm.clearanceProductSetting',
  path: '/psm/clearanceProductSetting',
  component: () => import('@/views/psm/clearanceProductSetting/clearanceProductSettingList.vue')
};

const transferProductConfigRoute = {
  name: 'psm.transferProductSetting',
  path: '/psm/transferProductSetting',
  component: () => import('@/views/psm/transferProductSetting/transferProductSettingList.vue')
};

const aviationConfigRoute = {
  path: '/psm/aviation',
  component: () => import('@/views/psm/aviation/AviationPage.vue'),
  name: 'psm.aviation'
};


export default {
  name: 'psm',
  component: () => import('@/layout/Layout.vue'),
  path: '/',
  children: [
    clearanceProductConfigRoute,
    transferProductConfigRoute,
    aviationConfigRoute
  ]
};
