const podRoute = {
  path: '/lms/pod',
  name: 'lms.pod',
  component: () => import('@/views/lms/pod/PodPage.vue'),
};

const returnShipmentRoute = {
  path: '/lms/returnShipment/list',
  component: () => import('@/layout/components/MicroApp/index.vue'),
  name: 'lms.returnShipment',
  meta: {
    clientAppRouteName: 'oms.returnShipment.list',
    clientApp: 'client'
  }
};

const shipmentRoute = [
  {
    path: '/lms/shipment/list',
    component: () => import('@/views/lms/shipment/shipmentPage.vue'),
    name: 'lms.shipment'
  },
  {
    path: '/lms/shipment/:id',
    component: () => import('@/views/lms/shipment/detail/shipmentDetailPage.vue'),
    name: 'lms.shipment.view',
    props: true
  }
];

const shipmentMonitorRoute = {
  path: '/lms/shipment/monitor',
  component: () => import('@/views/lms/shipmentOrderMonitor/ShipmentOrderMonitorPage.vue'),
  name: 'lms.orderLog'
};

const shipmentSupportRoute = [
  {
    path: '/lms/supportService',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'lms.supportService',
    meta: {
      clientAppRouteName: 'ass.supportService',
      clientApp: 'client'
    }
  },
  {
    path: '/lms/supportService/create',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'lms.supportService.create',
    meta: {
      clientAppRouteName: 'ass.supportService.create',
      clientApp: 'client'
    }
  }
];

export default {
  name: 'lms',
  component: () => import('@/layout/Layout.vue'),
  path: '/',
  children: [
    podRoute,
    returnShipmentRoute,
    ...shipmentRoute,
    shipmentMonitorRoute,
    ...shipmentSupportRoute
  ]
};
