import destocking from './dcm_destocking.json';
import box from './dcm_box.json';
import parcel from './dcm_parcel.json'
import charter from './dcm_charter.json';

export default {
  ...destocking,
  ...box,
  ...parcel,
  ...charter
};
