const lostTraceRoute = {
  path: '/fmm/lostTrace',
  name: 'fmm.lostTrace',
  component: () => import('@/views/fmm/lostTrace/LostTracePage.vue')
};

const hzcnTraceQuantityRoute = {
  path: '/fmm/hzcn/trace/quantity',
  name: 'fmm.hzcn.trace.quantity',
  component: () => import('@/views/fmm/hzcnTrace/HzcnTracePage.vue'),
};

const temuTraceQuantityRoute = {
  path: '/fmm/temu/trace/quantity',
  name: 'fmm.temu.trace.quantity',
  component: () => import('@/views/fmm/temuTrace/TemuTracePage.vue'),
};

const temuClearanceAgingRoute = {
  path: '/fmm/temu/clearance/aging',
  name: 'fmm.temu.clearance.aging',
  component: () => import('@/views/fmm/temuFranceEvaluation/TemuFranceEvaluationPage.vue'),
};

const temuSpainExamineRoute = {
  path: '/fmm/temu/spain/examine',
  name: 'fmm.temu.spain.examine',
  component: () => import('@/views/fmm/temuSpainEvaluation/TemuSpainEvaluationPage.vue'),
};

const temuBelgiumExamineRoute = {
  path: '/sim/temu/belgium/examine',
  component: () => import('@/views/fmm/temuBelgiumEvaluation/TemuBelgiumEvaluationPage.vue'),
  name: 'fmm.temu.be.examine'
};

export default {
  name: 'fmm',
  component: () => import('@/layout/Layout.vue'),
  path: '/',
  children: [
    lostTraceRoute,
    hzcnTraceQuantityRoute,
    temuTraceQuantityRoute,
    temuClearanceAgingRoute,
    temuSpainExamineRoute,
    temuBelgiumExamineRoute
  ]
};
