const ClientEventSettingRoute = {
  component: () => import('@/views/fsm/ClientEventSetting/index.vue'),
  name: 'fsm.client.event',
  path: '/fsm/clientEventSetting'
};

const ClientBaseEventSettingRoute = {
  component: () => import('@/views/fsm/ClientBaseEventSetting/ClientBasicEventPage.vue'),
  name: 'fsm.client.baseEvent',
  path: '/fsm/clientBaseEventSetting'
};

const ClientPushSettingRoute = {
  component: () => import('@/views/fsm/ClientPushSetting/ClientPushSettingPage.vue'),
  name: 'fsm.client.push',
  path: '/fsm/clientPushSetting'
};

const ClientKpiSettingRoute = {
  component: () => import('@/views/fsm/ClientKpiSetting/ClientKpiSettingPage.vue'),
  name: 'fsm.client.kpi',
  path: '/fsm/clientKpiSetting'
};

const TraceStrategySettingRoute = {
  component: () => import('@/views/fsm/TraceStrategySetting/TraceStrategySettingPage.vue'),
  name: 'fsm.trace.strategy',
  path: '/fsm/traceStrategySetting'
};

export default {
  name: 'fsm',
  component: () => import('@/layout/Layout.vue'),
  path: '/',
  children: [ClientEventSettingRoute, ClientKpiSettingRoute, ClientPushSettingRoute, TraceStrategySettingRoute, ClientBaseEventSettingRoute]
};
