import action from './common_action.json';
import placeholder from './common_placeholder.json';
import email from './common_email.json';
import layout from './common_layout.json';
import error from './common_error.json';
import status from './common_status.json';

export default {
    ...action,
    ...placeholder,
    ...email,
    ...status,
    ...layout,
    ...error
}