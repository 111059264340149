import type { selectOption } from '@/components/FtlSelect/type';
import i18n, { globalTranslate } from '@/i18n';

const countryList: selectOption = () => ['ES', 'IT', 'FR', 'DE', 'BE', 'PL'].map((val) => ({ label: val, value: val }));

const addressList = () =>
  [
    'FTL_SENDER_ADDRESS',
    'UPS_SENDER_ADDRESS',
    'CORREOS_SENDER_ADDRESS',
    'FR_SENDER_ADDRESS',
    'DHL_SENDER_ADDRESS',
    'CM_SENDER_ADDRESS'
  ].map((value) => ({
    label: globalTranslate(`psm.shipmentProductSetting.senderTemplate.${value}`) as string,
    value
  }));

const departureCountry = () =>
  ['FR', 'ES', 'BE','TR'].map((value) => ({
    label: value,
    value
  }));

const targetSystem = () =>
  ['WMS', 'SWMS'].map((value) => ({
    label: globalTranslate(`${value}`),
    value
  }));

const clearanceType = () =>
  ['FR_H1', 'FR_H7', 'FR_MIX', 'ES_H7'].map((value) => ({
    label: value,
    value
  }));

const priceStatus = () =>
  ['NOT_START', 'ACTIVE', 'EXPIRE', 'INVALID'].map((value) => ({
    label: globalTranslate(`crm.price.status.${value}`),
    value
  }));

export { countryList, addressList, departureCountry, targetSystem, clearanceType, priceStatus };
