import type { selectOption } from '@/components/FtlSelect/type';
import {
  requestClientInfo,
  requestTransferLine,
  requestTransferSuppliers,
  requestTransferClientCode,
  requestShipmentLine,
  requestSenderConfig,
  requestChannel,
  requestPriceLevel,
  requestClearanceCountry,
  requestClearanceType,
  requestClearanceAirpport
} from '@/components/FtlSelect/hooks/remote';
import {
  addressList,
  countryList,
  departureCountry,
  targetSystem,
  clearanceType,
  priceStatus
} from '@/components/FtlSelect/hooks/local';

const valueMap: Map<string, selectOption> = new Map([
  ['client', requestClientInfo],
  ['transferSuppliers', requestTransferSuppliers],
  ['transferLine', requestTransferLine],
  ['transferClientCode', requestTransferClientCode],
  ['country', countryList],
  ['address', addressList],
  ['clearanceAirport', requestClearanceAirpport],
  ['shipment', requestShipmentLine],
  ['senderConfig', requestSenderConfig],
  ['departureCountry', departureCountry],
  ['channel', requestChannel],
  ['targetSystem', targetSystem],
  ['clearanceType', clearanceType],
  ['priceLevel', requestPriceLevel],
  ['priceStatus', priceStatus],
  ['clearanceType', requestClearanceType],
  ['clearanceCountry', requestClearanceCountry]
]);

export { valueMap };
export { statusText, error, setClient, setMode, setPriceType } from './remote';
export type { selectOption };
