const clientRoute = [
  {
    path: '/crm/client',
    component: () => import('@/views/crm/client/list/clientList.vue'),
    name: 'crm.client'
  },
  {
    path: '/crm/client/:name',
    component: () => import('@/views/crm/client/details/clientDetails.vue'),
    name: 'crm.client.view'
  },
  {
    path: '/crm/client/:id/edit',
    component: () => import('@/views/crm/client/edit/clientEdit.vue'),
    name: 'crm.client.edit',
    props: true
  },
  {
    path: '/crm/client/create',
    component: () => import('@/views/crm/client/edit/clientEdit.vue'),
    name: 'crm.client.create'
  }
];

const clearanceProductRoute = {
  name: 'crm.clearanceProductAuth',
  component: () => import('@/views/crm/clearanceProductAuth/ClearanceProductAuthPage.vue'),
  path: '/crm/clearanceProductAuth'
};

const transferProductRoute = {
  component: () => import('@/views/crm/transferProductAuth/TransferProductAuthPage.vue'),
  name: 'crm.transferProductAuth',
  path: '/crm/transferProductAuth'
};

const priceRoute = [
  {
    path: '/crm/price',
    component: () => import('@/views/crm/price/PricePage.vue'),
    name: 'crm.price'
  }
];

export default {
  name: 'crm',
  component: () => import('@/layout/Layout.vue'),
  path: '/',
  children: [...clientRoute, clearanceProductRoute, transferProductRoute, ...priceRoute]
};
